<template>
  <Modal v-if="showModal" :title="promo.modalTitle" :strapi-image-object="promo.media" @close="close">
    <div class="flex flex-col gap-y-10 mb-10">
      <MoleculeModalRowButton :item="{ title: 'Акция в клубе', controls: promoTags }" />
      <MoleculeModalRowText class="!mb-0" :item="{ title: 'Об акции', text: promo.description }" />
    </div>
    <div class="flex flex-col md:grid grid-cols-7 md:gap-x-4">
      <div class="col-span-2 md:pt-1 -md:text-sm mb-1" />
      <div class="col-span-4 flex flex-wrap gap-[6px]">
        <div v-if="successShow">В&nbsp;ближайшее время наш менеджер свяжется с&nbsp;вами.</div>
        <form v-else class="flex flex-col gap-y-8 md:gap-y-10" @submit.prevent="send">
          <div>
            <MoleculeInputText
              v-model="payload.first_name"
              :error="submitted && error.first_name"
              label="Имя*"
              class="item-input mb-2"
              type="text"
              @blur="validate('first_name')"
              @input="validate('first_name')"
            />
            <MoleculeInputText
              v-model="payload.email"
              :error="submitted && error.email"
              label="Email*"
              type="email"
              class="item-input mb-2"
              @blur="validate('email')"
              @input="validate('email')"
            />
            <MoleculeInputPhone
              :error="submitted && error.phone"
              :value="payload.phone"
              label="Телефон*"
              type="tel"
              theme="white"
              @onInput="onInputPhone"
              @blur="onInputPhone"
            />
            <MoleculeTextarea
              v-model="payload.message"
              :error="submitted && error.message"
              label="Сообщение"
              class="item-input mb-2"
              @blur="validate('message')"
              @input="validate('message')"
            />
          </div>
          <MoleculeRadio
            v-model="radio"
            :error="submitted && error.radio"
            type="radio"
            :checked="radio"
            label="Я соглашаюсь с условиями обработки <br /> персональных данных"
            @input="error.radio = !radio"
          />
          <AtomButton :disabled="submitting" class="md:self-baseline w-full" tag="button" type="submit"
            >УЧАСТВОВАТЬ</AtomButton
          >
          <div v-if="errorShow" class="text-red text-center text-sm mt-5">Произошла ошибка. Попробуйте позже</div>
        </form>
      </div>
    </div>
  </Modal>
</template>

<script>
import AtomButton from '@/components/atoms/AtomButton'
import MoleculeInputText from '@/components/molecules/forms/MoleculeInputText'
import MoleculeInputPhone from '@/components/molecules/forms/MoleculeInputPhone'
import MoleculeRadio from '@/components/molecules/forms/MoleculeRadio'
import MoleculeTextarea from '@/components/molecules/forms/MoleculeTextarea'
import MoleculeModalRowButton from '@/components/molecules/modals/MoleculeModalRowButton'
import Modal from '@/components/templates/layouts/Modal'
import MoleculeModalRowText from '@/components/molecules/modals/MoleculeModalRowText'

export default {
  components: {
    MoleculeModalRowText,
    Modal,
    AtomButton,
    MoleculeInputText,
    MoleculeInputPhone,
    MoleculeTextarea,
    MoleculeRadio,
    MoleculeModalRowButton,
  },
  data: () => {
    return {
      payload: {
        first_name: '',
        email: '',
        phone: '',
        message: '',
      },
      error: {
        first_name: null,
        email: null,
        phone: null,
        message: null,
        radio: null,
      },
      submitted: false,
      submitting: false,
      successShow: false,
      errorShow: false,
      radio: false,
      modal: null,
    }
  },
  computed: {
    showModal() {
      return !this.$store.getters['entryLoaderScreen/hasPreloadScreen']
    },
    club() {
      return this.$store.getters['modules/content/clubs'].find(c => c.slug === this.$route.params.id) || { title: '' }
    },
    promo() {
      return (this.club.actions || [])[0]
    },
    promoTags() {
      return (this.promo.clubs || []).map(({ title }) => ({ title }))
    },
  },
  mounted() {
    const {
      params: { slug },
    } = this.$route

    if (slug && slug !== this.promo.slug) {
      return this.$nuxt.error({ statusCode: 404, message: 'Страница не найдена' })
    }
  },
  destroyed() {
    const isToParentRoute = this.$route.path.startsWith('/team')
    if (isToParentRoute) {
      this.$store.dispatch('pageTransition/toDefault')
    }
  },
  methods: {
    getUtmList() {
      const utmList = {}

      // eslint-disable-next-line camelcase
      const utm_term = this.$cookies?.get('utm_term') || ''
      // eslint-disable-next-line camelcase
      const utm_source = this.$cookies?.get('utm_source') || ''
      // eslint-disable-next-line camelcase
      const utm_medium = this.$cookies?.get('utm_medium') || ''
      // eslint-disable-next-line camelcase
      const utm_campaign = this.$cookies?.get('utm_campaign') || ''
      // eslint-disable-next-line camelcase
      const utm_content = this.$cookies?.get('utm_content') || ''

      // eslint-disable-next-line camelcase
      if (utm_term) {
        // eslint-disable-next-line camelcase
        utmList.utm_term = utm_term
      }
      // eslint-disable-next-line camelcase
      if (utm_source) {
        // eslint-disable-next-line camelcase
        utmList.utm_source = utm_source
      }
      // eslint-disable-next-line camelcase
      if (utm_medium) {
        // eslint-disable-next-line camelcase
        utmList.utm_medium = utm_medium
      }
      // eslint-disable-next-line camelcase
      if (utm_campaign) {
        // eslint-disable-next-line camelcase
        utmList.utm_campaign = utm_campaign
      }
      // eslint-disable-next-line camelcase
      if (utm_content) {
        // eslint-disable-next-line camelcase
        utmList.utm_content = utm_content
      }

      return utmList
    },
    send() {
      this.submitted = true
      if (this.validateForm()) {
        const phone = `+${this.payload.phone.replace(/[^0-9]/g, '')}`
        let visitorUid = 'unknown'
        try {
          visitorUid = window.AMOPIXEL.getVisitorUid()
        } catch (e) {}
        this.submitting = true

        const utmList = this.getUtmList()

        const payload = {
          ...this.payload,
          phone,
          action: this.promo.modalTitle,
          club: this.club.slug,
          visitor_uid: visitorUid,
          ...utmList,
        }

        const sessionId = window?.ct('calltracking_params', 'b4d68ca9')?.sessionId || 0

        if (sessionId) {
          payload.sessionId = sessionId
        }

        this.$axios
          .post('/api/forms/action', {
            data: payload,
          })
          .then(() => {
            this.successShow = true
            this.sendReachGoal('success-form-action', { api: 'forms/action', action: this.promo.modalTitle })
          })
          .catch(() => {
            this.errorShow = true
          })
          .finally(() => {
            this.submitting = false
          })
      }
    },
    validateForm() {
      Object.keys(this.payload).forEach(key => {
        this.validate(key)
      })
      this.error.radio = !this.radio
      return !Object.values(this.error).some(Boolean)
    },
    validate(key) {
      const textIsInvalid = v => !(v || '').trim()
      const schema = {
        first_name: textIsInvalid,
        email: textIsInvalid,
        phone: v => !v || v.length !== 16,
        message: textIsInvalid,
      }
      if (key in this.error && schema[key]) {
        const value = this.payload[key]
        this.error[key] = schema[key](value)
      }
    },
    onInputPhone(value, objValidPhone) {
      this.payload.phone = value

      if (!this.payload.phone || !objValidPhone.isValid) this.error.phone = !objValidPhone.isValid
      else this.error.phone = false
    },
    close() {
      const canBack = history.length > 2
      if (canBack) {
        this.$router.back()
      } else {
        this.$router.push(`/clubs/${this.$route.params.id}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
