<template>
  <div class="flex flex-col md:grid grid-cols-7 md:gap-x-4 w-full">
    <p class="col-span-2 md:pt-1 -md:text-sm mb-1">{{ item.title }}</p>
    <div class="col-span-5 flex flex-wrap gap-[6px] list-btn">
      <AtomButton
        v-for="control in item.controls"
        :key="control.name"
        :to="control.to"
        :class="(control.disabled, control.class)"
        class="btn btn-sm btn-outline self-start"
        @click="control.click && control.click($event)"
        ><span class="first-letter:uppercase">{{ control.title }}</span></AtomButton
      >
    </div>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'

export default {
  name: 'MoleculeModalRowButton',
  components: {
    AtomButton,
  },
  props: ['item'],
}
</script>

<style lang="postcss" scoped>
.list-btn {
  &:after {
    content: '';
    @apply order-1 w-full block;
  }
  .btn-inverted {
    @apply relative top-[-6px] order-2;
  }
}
</style>
