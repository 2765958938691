<template>
  <div>
    <Portal :to="nested ? 'modal-nested' : 'modal'">
      <transition :duration="nested ? {} : { enter: 1600, leave: 1000 }" name="modal">
        <div v-if="mounted" class="fixed md:left-[75px] left-0 inset-y-0 right-0">
          <div class="relative h-full">
            <div v-if="!nested" class="modal-backdrop absolute inset-0 bg-gray-100"></div>
            <div
              class="modal-overflow overflow-hidden absolute right-0 inset-y-0 w-full bg-white border-solid border-white"
              :class="small ? '!inset-y-5 !inset-x-3 rounded-[14px] !w-auto' : null"
            >
              <div
                class="flex flex-col md:w-[calc(100vw-75px)] w-[100vw] absolute -md:left-0 right-0 min-h-full max-h-full"
                :class="small ? '!w-auto' : null"
              >
                <div
                  class="modal-header m-3 md:m-5 mb-0 md:mb-0 py-3 border-y border-gray-500 border-solid flex justify-between items-center"
                  :class="small ? '!border-t-0 pt-0' : null"
                >
                  <div
                    class="modal-title flex flex-col md:flex-row flex-wrap md:items-center gap-2 md:gap-4"
                    :class="small ? '!flex-row w-full !justify-between' : null"
                  >
                    <component
                      :is="asPage ? 'h1' : 'h2'"
                      class="pr-5 flex items-center"
                      :class="small ? '!text-base' : null"
                    >
                      <svg
                        v-if="icon"
                        class="mr-2"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.0006 3.27502H10.1006"
                          stroke="#282929"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.59999 3.27502H1"
                          stroke="#282929"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.19882 5.54998C7.45526 5.54998 8.47381 4.53144 8.47381 3.27499C8.47381 2.01855 7.45526 1 6.19882 1C4.94238 1 3.92383 2.01855 3.92383 3.27499C3.92383 4.53144 4.94238 5.54998 6.19882 5.54998Z"
                          stroke="#282929"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.9994 10.425H11.3994"
                          stroke="#282929"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.89999 10.425H1"
                          stroke="#282929"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.79941 12.6999C10.0558 12.6999 11.0744 11.6814 11.0744 10.425C11.0744 9.16851 10.0558 8.14996 8.79941 8.14996C7.54296 8.14996 6.52441 9.16851 6.52441 10.425C6.52441 11.6814 7.54296 12.6999 8.79941 12.6999Z"
                          stroke="#282929"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {{ title }}
                    </component>
                    <slot name="controls" />
                  </div>
                  <AtomButton
                    class="modal-close btn-sm btn-fill"
                    :class="small ? '!bottom-10' : null"
                    tag="button"
                    @click="$emit('close')"
                  >
                    <span class="-md:hidden">Закрыть</span>
                    <svg
                      class="md:hidden"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 1L14 14M1 14L14 1" stroke="white" />
                    </svg>
                  </AtomButton>
                </div>
                <div
                  class="overflow-auto mr-[-20px] flex flex-col flex-1"
                  :class="small ? '!mb-[70px] !overflow-x-clip' : null"
                >
                  <div class="modal-content -md:pb-16 pr-5 flex flex-col flex-1 mb-5">
                    <div class="modal-body mx-3 md:mx-5 flex flex-col md:flex-row grow md:border-b border-gray-500">
                      <div
                        class="modal-image relative w-full md:w-2/5 pt-5 md:pr-5 pb-5 md:border-r border-gray-500 border-solid"
                        :class="small ? '!hidden' : null"
                      >
                        <AtomImage
                          v-if="img || strapiImageObject"
                          class="w-full object-cover object-center"
                          :class="disalbeImageHeightStretch ? '' : 'md:h-full'"
                          :src="img"
                          :strapi-image-object="strapiImageObject"
                        />
                      </div>
                      <div class="modal-content w-full md:w-3/5 md:p-5 md:pr-0">
                        <slot />
                      </div>
                    </div>
                    <div
                      v-if="$slots.footer"
                      class="modal-footer md:ml-[40%] mx-3 md:m-5 flex items-center -md:border-t border-gray-500 -md:pt-3"
                    >
                      <slot name="footer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </Portal>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomImage from '../../atoms/AtomImage'

export default {
  name: 'Modal',
  components: {
    AtomButton,
    AtomImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      default: null,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    strapiImageObject: {
      type: Object,
      default: null,
    },
    disalbeImageHeightStretch: {
      type: Boolean,
      default: false,
    },
    asPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  mounted() {
    this.mounted = true
  },
}
</script>

<style lang="postcss">
.modal-enter,
.modal-leave-to {
  .modal-backdrop {
    @apply opacity-0;
  }
  .modal-overflow {
    @apply w-0 opacity-0;
  }
  .modal-header {
    .modal-title {
      @apply translate-x-20 opacity-0;
    }
    .modal-close {
      @apply opacity-0;
    }
  }
  .modal-body {
    .modal-image img {
      @apply translate-x-[40px] opacity-0;
    }
    .modal-image::after {
      @apply opacity-50;
    }
    .modal-content {
      @apply translate-x-10 opacity-0;
    }
  }
}
.modal-enter-to,
.modal-leave {
  .modal-backdrop {
    @apply opacity-100;
  }
  .modal-overflow {
    @apply w-full opacity-100;
  }
  .modal-header {
    .modal-title {
      @apply translate-x-0 opacity-100;
    }
    .modal-close {
      @apply opacity-100;
    }
  }
  .modal-body {
    .modal-image img {
      @apply translate-x-0 opacity-100;
    }
    .modal-image::after {
      @apply opacity-0;
    }
    .modal-content {
      @apply translate-x-0 opacity-100;
    }
  }
}
.modal-enter-active,
.modal-leave-active {
  .modal-backdrop {
    transition: opacity 0.8s var(--cubic-bezier);
  }
  .modal-overflow {
    transition: width 1s var(--cubic-bezier), opacity 0.5s var(--cubic-bezier);
  }
  .modal-header {
    .modal-title {
      transition: transform 1.2s var(--cubic-bezier), opacity 1.2s var(--cubic-bezier);
    }
    .modal-close {
      transition: opacity 0.4s var(--cubic-bezier);
    }
  }
  .modal-body {
    .modal-image img {
      transition: transform 0.8s var(--cubic-bezier), opacity 0.6s var(--cubic-bezier);
    }
    .modal-image::after {
      content: '';
      @apply absolute inset-px bg-white;
      transition: opacity 0.8s ease-in;
    }
    .modal-content {
      transition: transform 1s var(--cubic-bezier), opacity 1s var(--cubic-bezier);
    }
  }
}
.modal-enter-active {
  .modal-header {
    .modal-title {
      transition-delay: 0.4s;
    }
    .modal-close {
      transition-delay: 0.5s;
    }
  }
  .modal-body {
    .modal-image img {
      transition-delay: 0.4s;
    }
    .modal-image::after {
      transition-delay: 0.3s;
    }
    .modal-content {
      transition-delay: 0.6s;
    }
  }
}
.modal-leave-active {
  .modal-backdrop {
    transition-delay: 0.2s;
  }
}
@screen -md {
  .modal-close {
    @apply fixed rounded-full bottom-5 z-10 w-10 h-10 inline-flex items-center justify-center p-0 left-1/2 ml-[-20px];
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    &:not(:disabled):not(.disabled):not(:active):hover:before {
      transform: translate3d(0, 100%, 0) !important;
    }
  }
}
.modal-title h1 {
  @apply text-xl -md:text-md;
}
</style>
