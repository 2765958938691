<template>
  <div class="text-sm md:text-base" :class="className">
    <div v-if="content" class="wrap-text" v-html="content" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AtomText',
  props: ['content', 'className'],
}
</script>

<style lang="postcss">
.wrap-text p {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
</style>
