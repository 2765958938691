<template>
  <div class="inline-flex items-end w-full min-h-[41px] relative input-container">
    <AtomTextarea :theme="theme" :value="value" @input="emit" @blur="$emit('blur')" />
    <AtomInputLabel :id-for="id" :label="label" :theme="theme" />
  </div>
</template>

<script>
import AtomInputLabel from '../../atoms/forms/AtomInputLabel'
import AtomTextarea from '../../atoms/forms/AtomTextarea'

export default {
  name: 'MoleculeInputText',
  components: {
    AtomTextarea,
    AtomInputLabel,
  },
  props: ['label', 'id', 'type', 'theme', 'value'],
  methods: {
    emit(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="postcss" scoped>
.textarea:focus ~ .placeholder,
.textarea:not(:placeholder-shown) ~ .placeholder {
  @apply text-gray-500 border-white;
  transform: translateY(-20px) scale(0.75);
}
.input-container {
  &[error~='true'] {
    .placeholder {
      @apply !text-red;
    }
    .textarea {
      @apply !border-red !text-red;
    }
  }
}
</style>
