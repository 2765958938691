<template>
  <div class="flex flex-col md:grid grid-cols-7 md:gap-x-4 mb-10 md:mb-[60px]">
    <p class="col-span-2 -md:text-sm mb-3 md:mb-1">{{ item.title }}</p>
    <div class="col-span-5 flex flex-wrap gap-[6px]">
      <AtomText :content="item.text" class-name="whitespace-pre-wrap" />
    </div>
  </div>
</template>

<script>
import AtomText from '../../atoms/AtomText'

export default {
  name: 'MoleculeModalRowText',
  components: {
    AtomText,
  },
  props: ['item'],
}
</script>
