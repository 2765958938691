<template>
  <textarea
    ref="textarea"
    :value="value"
    class="textarea w-full p-0 border-b rounded-none outline-none bg-transparent appearance-none h-[26px] mt-[15px]"
    :class="{ [`${theme || 'white'}`]: true }"
    :disabled="disabled"
    placeholder=" "
    @input="update($event.target.value)"
    @blur="$emit('blur')"
  ></textarea>
</template>

<script>
export default {
  name: 'AtomTextarea',
  props: ['value', 'disabled', 'theme'],
  methods: {
    update(value) {
      this.$refs.textarea.style.height = 0
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="postcss" scoped>
.textarea {
  &.dark {
    @apply text-gray-500 hover:text-white active:text-white hover:border-white active:border-white;
    &:hover {
      transition: border 1s var(--cubic-bezier);
      & ~ .placeholder {
        @apply text-white;
      }
    }
  }
  &.white {
    @apply border-gray-500 hover:border-gray-100;
  }
}
</style>
